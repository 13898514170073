import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    logged_in: false,
    profile: {},
    customer_info: [],
    last_selected_customer_id: null,
  },
  getters: {
    customerWithId: (state) => (id) => {
      if (state.profile.customers) {
        return state.profile.customers.find((x) => x.id == id);
      }
      return null;
    },
    customerInfoWithId: (state) => (id) => {
      if (state.customer_info) {
        return state.customer_info.find((x) => x.customer.id == id);
      }
      return null;
    },
  },
  mutations: {
    setLoggedIn(state) {
      state.logged_in = true;
    },
    setLoggedOut(state) {
      state.logged_in = false;
    },
    setProfile(state, n) {
      state.profile = n;
    },
    setCustomerInfo(state, n) {
      if (n) {
        state.customer_info.push(n);
      }
    },
    setSelectedCustomerId(state, n) {
      state.last_selected_customer_id = n;
    },
    logout(state) {
      state.customer_info = [];
      state.last_selected_customer_id = 0;
    },
  },
  actions: {
    setLoggedIn({ commit }) {
      commit("setLoggedIn");
    },
    setLoggedOut({ commit }) {
      commit("setLoggedOut");
    },
    setProfile({ commit }, p) {
      commit("setProfile", p);
    },
    setCustomerInfo({ commit }, p) {
      commit("setCustomerInfo", p);
    },
    setSelectedCustomerId({ commit }, p) {
      commit("setSelectedCustomerId", p);
    },
    logout({ commit }) {
      commit("logout");
    },
  },
  modules: {},
});
