<template>
  <div class="home">
    <v-container fluid>
      <div v-if="logged_in">
        <v-row>
          <v-col>
            <v-card class="">
              <v-card-title>
                Welkom {{ firstname }} {{ lastname }}
              </v-card-title>
              <v-list>
                <v-subheader>BEDRIJVEN</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(item, i) in customers"
                    v-show="bestPageForAcessTo(item.access_to)"
                    :key="i"
                    :to="{
                      name: bestPageForAcessTo(item.access_to),
                      params: {
                        customerId: item.id,
                      },
                    }"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <img
                        style="height: 30px;"
                        :src="
                          require('@/assets/company-logos/' +
                            item.company_logo +
                            '.svg')
                        "
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  beforeMount() {
    if (this.customers && this.customers.length == 1) {
      this.$router.push({
        name: this.bestPageForAcessTo(this.customers[0].access_to),
        params: {
          customerId: this.customers[0].id,
        },
      });
    }
  },
  methods: {
    bestPageForAcessTo(access_to) {
      if (access_to.indexOf("export") !== -1) {
        return "customer";
      }
      if (access_to.indexOf("returnfile") !== -1) {
        return "retouren";
      }
      if (access_to.indexOf("emailkit") !== -1) {
        return "emailkits";
      }
      return "";
    },
  },
};
</script>
