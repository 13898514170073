<template>
  <div class="tfa_enter">
    <v-container fluid>
      <div v-if="tfa_authenticated">
        <v-alert prominent icon="check" dark color="success"
          ><v-row align="center">
            <v-col class="grow">Code bevestigd </v-col>
            <v-col v-if="$route.query.redirect" class="shrink">
              <v-btn :to="$route.query.redirect">Ga verder </v-btn>
            </v-col>
            <v-col v-else class="shrink">
              <v-btn to="/">Ga verder </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>
      <div v-else>
        <v-row>
          <v-col>
            <v-card class="">
              <v-card-title>Verificatiecode</v-card-title>
              <form @submit.prevent="validate2FA">
                <v-card-text>
                  <p>Vul hieronder de verificatiecode uit je 2FA app in.</p>
                  <v-text-field
                    v-model="otp"
                    autocomplete="one-time-code"
                    label="Vul hier de verificatiecode in"
                    :error="invalid_token"
                  >
                  </v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn type="submit" color="blue" dark block>
                    Ga verder
                  </v-btn>
                </v-card-actions>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "EnterTFA",
  data() {
    return {
      tfa_configured: null,
      tfa_authenticated: null,
      otp: "",
      invalid_token: false,
    };
  },
  watch: {
    otp() {
      this.invalid_token = false;
    },
    otp_delete() {
      this.invalid_token = false;
    },
  },
  mounted() {
    this.axios
      .get("tfa")
      .then((res) => {
        this.tfa_configured = res.data.tfa_configured;
        this.tfa_authenticated = res.data.tfa_authenticated;

        if (this.tfa_authenticated && this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        }
      })
      .catch(() => {});
  },
  methods: {
    validate2FA() {
      this.axios
        .put("tfa", {
          otp: this.otp,
        })
        .then((res) => {
          this.tfa_configured = res.data.tfa_configured;
          this.tfa_authenticated = res.data.tfa_authenticated;

          if (this.tfa_authenticated && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response && error.response.status == 406) {
            // Too many attempts
            alert("Te veel poginingen, probeer over 1 min weer.");
          }
          this.invalid_token = true;
        });
    },
  },
};
</script>
