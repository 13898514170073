<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-form @submit.prevent="processLogin">
          <v-card class="elevation-12">
            <v-toolbar color="blue" dark flat>
              <v-toolbar-title>Inloggen</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert v-if="login_blocked" dark color="red">
                Te veel loginpogingen. Wacht 10 minuten en probeer opnieuw.
              </v-alert>
              <v-text-field
                v-model="username"
                label="E-mailadres"
                :error="login_failed"
                prepend-icon="email"
                type="email"
                required
              />

              <v-text-field
                id="password"
                v-model="password"
                label="Password"
                :error="login_failed"
                prepend-icon="lock"
                type="password"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn type="submit" color="blue lighten-4">
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      login_failed: false,
      login_blocked: false,
    };
  },
  watch: {
    username() {
      this.login_failed = false;
    },
    password() {
      this.login_failed = false;
    },
  },
  mounted() {},
  methods: {
    processLogin() {
      if (!this.username || !this.password) {
        this.login_failed = true;
        return;
      }
      const data = new FormData();
      data.append("login_username", this.username);
      data.append("login_password", this.password);

      this.axios
        .post("profile", data)
        .then((res) => {
          this.$store.dispatch("setLoggedIn");
          this.$store.dispatch("setProfile", res.data.profile);

          if (
            this.$route.query.redirect &&
            !this.$route.query.redirect.match(/^\/login/)
          ) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({
              name: "home",
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status) {
            if (error.response.data.blocked === true) {
              this.login_blocked = true;
            }
            if (error.response.data.authentication_required === true) {
              this.login_failed = true;
            }
          }
        });
    },
  },
};
</script>
