var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-row',[_c('v-col',[(_vm.sponsor)?_c('v-card',{},[_c('v-card-title',[_vm._v(_vm._s(_vm.sponsor.name)+" exports")]),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.filterDates,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Filter periode","prepend-icon":"event","readonly":"","range":"","clear-icon":"close","clearable":""},on:{"click:clear":function($event){_vm.filterDates = []}},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}],null,false,2642221215),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.filterDates),callback:function ($$v) {_vm.filterDates=$$v},expression:"filterDates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.filterDates)}}},[_vm._v("OK ")])],1)],1)],1)],1),_c('v-data-table',{ref:"dataTableOverview",staticClass:"elevation-1",attrs:{"loading":_vm.sponsor_exports === null,"no-data-text":"Geen exports gevonden","loading-text":"Bezig met laden..","headers":_vm.tableHeaders,"items":_vm.tableItems,"items-per-page":_vm.itemsPerPage,"footer-props":{
              itemsPerPageOptions: [10, 50, 100, 200],
            }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" "),(item.is_new)?_c('v-chip',{attrs:{"color":"success","small":true,"label":true}},[_vm._v("Nieuw")]):_vm._e()]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.generated)?_c('v-btn',{attrs:{"small":"","href":_vm.downloadLinkForItem(item)},on:{"click":function($event){return _vm.removeNewFromItem(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("arrow_downward")]),_vm._v(" download "),_c('span',{staticStyle:{"margin-left":"4px"}},[_vm._v(_vm._s(item.file_type))])],1):_c('span',[_vm._v(" Wachten op file.. ")])]}}],null,false,1638085738)},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('strong',[_vm._v("Totaal op pagina")])]),_c('td',[_vm._v(_vm._s(_vm.totalOnPage))]),_c('td')]),_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('strong',[_vm._v("Totaal in selectie")])]),_c('td',[_vm._v(_vm._s(_vm.totalInSelection))]),_c('td')])])],2)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }