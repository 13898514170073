import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      logged_in: (state) => state.logged_in,
      firstname: (state) => state.profile.firstname,
      lastname: (state) => state.profile.lastname,
      customers: (state) => state.profile.customers,
      last_selected_customer_id: (state) => state.last_selected_customer_id,
    }),
    total_new_exports() {
      if (!this.last_selected_customer) {
        return 0;
      }
      return this.last_selected_customer.sponsors
        .map((s) => s.num_exports_new)
        .reduce(function (total, next) {
          return total + next;
        }, 0);
    },
    selected_customer() {
      return this.$store.getters.customerWithId(this.customerId);
    },
    selected_customer_info() {
      return this.$store.getters.customerInfoWithId(this.customerId);
    },
    last_selected_customer() {
      if (this.last_selected_customer_id) {
        return this.$store.getters.customerInfoWithId(
          this.last_selected_customer_id
        );
      }
      return null;
    },
  },
};
