export default {
  props: {
    customerId: Number,
  },
  filters: {
    /**
     * @param {number} v
     */
    formatCount: function (v) {
      return new Intl.NumberFormat().format(v);
    },
    /**
     * @param {number} value
     * @param {number} total
     */
    formatPercentageOf: function (value, total) {
      let percentage = 0;
      if (total > 0) {
        percentage = value / total;
      }
      return new Intl.NumberFormat("nl-NL", {
        style: "percent",
        maximumFractionDigits: 1,
      }).format(percentage);
    },
  },
  computed: {
    defaultBreadcrumbs() {
      let b = [];
      if (
        this.customers &&
        this.customers.length > 0 &&
        this.selected_customer_info
      ) {
        // bedrijven overzicht
        if (this.customers.length > 1) {
          b.push({
            text: "Bedrijven",
            exact: true,
            to: "/",
          });
        }
        // main sponsor
        b.push({
          text: this.selected_customer_info.customer.name,
          exact: true,
          to: {
            name: "customer",
            params: {
              customerId: this.customerId,
            },
          },
        });
      }

      return b;
    },
  },
  mounted() {
    if (!this.selected_customer_info) {
      // try to load profile
      /**
       * @param {{ data: any; }} res
       */
      /**
       * @param {{ response: { status: number; }; }} error
       */
      this.axios
        .get("customer/" + this.customerId)
        .then((res) => {
          this.$store.dispatch("setCustomerInfo", res.data);
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            // sponsor not found
            this.$router.push("/");
          }
        });
    }

    if (this.last_selected_customer_id != this.customerId) {
      this.$store.dispatch("setSelectedCustomerId", this.customerId);
    }
  },
};
