<template>
  <div class="customer">
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row>
        <v-col>
          <v-card class="">
            <v-card-title>Campagnes</v-card-title>
            <v-simple-table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Campagne</th>
                  <th>Aantal exports</th>
                  <th>Laatste export</th>
                  <th class="text-right">Status</th>
                </tr>
              </thead>
              <tbody
                v-if="selected_customer_info && selected_customer_info.sponsors"
                class="router-links"
              >
                <router-link
                  v-for="(sponsor, sid) in selected_customer_info.sponsors"
                  :key="sid"
                  :to="{
                    name: 'exports',
                    params: {
                      customerId: last_selected_customer_id,
                      sponsorId: sponsor.id,
                    },
                  }"
                  tag="tr"
                >
                  <td v-ripple>{{ sponsor.id }}</td>
                  <td v-ripple>{{ sponsor.name }}</td>
                  <td v-ripple>
                    {{ sponsor.num_exports }}
                    <v-chip
                      v-if="sponsor.num_exports_new"
                      color="warning"
                      :small="true"
                      >{{ sponsor.num_exports_new }} nieuw
                    </v-chip>
                  </td>
                  <td v-ripple>{{ sponsor.last_export }}</td>
                  <td v-ripple class="text-right">
                    <v-chip
                      v-if="sponsor.status == 'active'"
                      color="success"
                      :small="true"
                      :label="true"
                      >Actief</v-chip
                    >
                    <v-chip v-else color="red" :small="true" :label="true"
                      >Uitgeschakeld</v-chip
                    >
                  </td>
                </router-link>
                <tr
                  v-if="
                    selected_customer_info &&
                    selected_customer_info.sponsors.length == 0
                  "
                >
                  <td colspan="5">Geen campagnes gevonden</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CustomerPage from "@/mixins/customerpage";

export default {
  name: "Customer",
  mixins: [CustomerPage],
  computed: {
    breadcrumbs() {
      let b = this.defaultBreadcrumbs.slice();

      b.push({
        disabled: true,
        text: "Campagnes",
      });

      return b;
    },
  },
};
</script>
