<template>
  <div class="customer">
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row>
        <v-col>
          <v-card v-if="sponsor" class="">
            <v-card-title>{{ sponsor.name }} exports</v-card-title>
            <v-list-item three-line>
              <v-list-item-content>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value="filterDates"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Filter periode"
                      prepend-icon="event"
                      readonly
                      range
                      clear-icon="close"
                      clearable
                      v-on="on"
                      @click:clear="filterDates = []"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filterDates"
                    no-title
                    scrollable
                    range=""
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(filterDates)"
                      >OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-list-item-content>
            </v-list-item>

            <v-data-table
              ref="dataTableOverview"
              :loading="sponsor_exports === null"
              no-data-text="Geen exports gevonden"
              loading-text="Bezig met laden.."
              :headers="tableHeaders"
              :items="tableItems"
              :items-per-page.sync="itemsPerPage"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100, 200],
              }"
            >
              <template slot="body.append">
                <tr>
                  <td colspan="4"><strong>Totaal op pagina</strong></td>
                  <td>{{ totalOnPage }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="4"><strong>Totaal in selectie</strong></td>
                  <td>{{ totalInSelection }}</td>
                  <td></td>
                </tr>
              </template>
              <template v-slot:item.id="{ item }">
                {{ item.id }}
                <v-chip
                  v-if="item.is_new"
                  color="success"
                  :small="true"
                  :label="true"
                  >Nieuw</v-chip
                >
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="item.generated"
                  small
                  :href="downloadLinkForItem(item)"
                  @click="removeNewFromItem(item)"
                >
                  <v-icon left>arrow_downward</v-icon> download
                  <span style="margin-left: 4px">{{ item.file_type }}</span>
                </v-btn>
                <span v-else> Wachten op file.. </span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import CustomerPage from "@/mixins/customerpage";

export default {
  name: "Customer",
  mixins: [CustomerPage],
  props: {
    sponsorId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sponsor_exports: null,
      itemsPerPage: 50,
      filterDates: [],
      menu: null,
    };
  },
  computed: {
    dateRangeText: {
      get() {
        if (this.filterDates.length == 0) {
          return "";
        }
        return this.filterDates.join(" tot ");
      },
      set(value) {
        this.filterDates = [];
      },
    },
    totalOnPage() {
      if (this.sponsor_exports && this.$refs.dataTableOverview) {
        return this.$refs.dataTableOverview._data.internalCurrentItems.reduce(
          (a, b) => a + b.num_unique_leads,
          0
        );
      }
      return "x";
    },
    totalInSelection() {
      return this.tableItems.reduce((a, b) => a + b.num_unique_leads, 0);
    },
    tableItems() {
      if (this.filterDates.length == 0) {
        return this.allTableItems;
      }

      let start_date = new Date(this.filterDates[0]);
      let end_date = new Date(
        (this.filterDates.length == 2
          ? this.filterDates[1]
          : this.filterDates[0]) + "T23:59:59"
      );

      return this.allTableItems.filter((item) => {
        let item_date = new Date(item.creation_date.replace(" ", "T"));
        return item_date >= start_date && item_date <= end_date;
      });
    },
    allTableItems() {
      if (!this.sponsor_exports) {
        return [];
      }
      return this.sponsor_exports.map((e) => {
        return {
          id: e.id,
          name: e.name,
          creation_date: e.creation_date,
          csv_format: e.csv_format.name,
          num_unique_leads: e.num_unique_leads,
          is_new: e.is_new,
          generated: e.generated,
          file_type: e.file_type,
        };
      });
    },
    tableHeaders() {
      return [
        {
          text: "#",
          value: "id",
        },
        {
          text: "Naam",
          value: "name",
        },
        {
          text: "Datum",
          value: "creation_date",
        },
        {
          text: "CSV formaat",
          sortable: false,
          value: "csv_format",
        },
        {
          text: "Aantal leads",
          value: "num_unique_leads",
        },
        {
          text: "",
          value: "action",
          align: "end",
        },
      ];
    },
    breadcrumbs() {
      let b = this.defaultBreadcrumbs.slice();

      b.push({
        text: "Campagnes",
        exact: true,
        to: {
          name: "customer",
          params: {
            customerId: this.customerId,
          },
        },
      });
      if (this.sponsor) {
        b.push({
          disabled: true,
          text: this.sponsor.name + " exports",
        });
      }

      return b;
    },
    sponsor() {
      return this.selected_customer_info
        ? this.selected_customer_info.sponsors.find(
            (x) => x.id == this.sponsorId
          )
        : null;
    },
  },
  mounted() {
    // load exports
    this.axios
      .get(
        "customer/" + this.customerId + "/sponsor/" + this.sponsorId + "/export"
      )
      .then((res) => {
        this.sponsor_exports = res.data.exports;

        if (this.$route.query && this.$route.query.download) {
          let exp = this.sponsor_exports.find(
            (x) => x.id == this.$route.query.download
          );
          location.href = this.downloadLinkForItem(exp);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 404) {
          // sponsor not found
          this.$router.push({
            name: "customer",
            params: {
              customerId: this.customerId,
            },
          });
        }
      });
  },
  methods: {
    downloadLinkForItem(item) {
      return (
        this.axios.defaults.baseURL +
        "customer/" +
        this.customerId +
        "/sponsor/" +
        this.sponsorId +
        "/exports/" +
        item.id +
        "/download"
      );
    },
    removeNewFromItem(item) {
      item.is_new = false;
    },
  },
};
</script>
