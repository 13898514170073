export default {
  computed: {
    appName() {
      if (this.isDMLeads()) {
        return "DMLeads";
      }
      return "Re:publish Leads";
    },
  },
  methods: {
    isDMLeads() {
      var host = location.host;
      if (host.match(/dmleads\.nl/)) {
        return true;
      }
      return false;
    },
  },
};
