import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import axios from "axios";
import VueAxios from "vue-axios";
import mapState from "./mixins/mapstate";
import axiosRetry from "axios-retry";
import bugsnag from "@bugsnag/js";
import bugsnagVue from "@bugsnag/plugin-vue";
const bugsnagClient = bugsnag("38520e2add3b685169fcfa96a3e34876");
bugsnagClient.use(bugsnagVue, Vue);

axiosRetry(axios, {
  retries: 2,
  retryDelay: axiosRetry.exponentialDelay,
});

axios.defaults.baseURL =
  location.protocol +
  "//" +
  location.host.replace(/:\d+$/, "") +
  "/customer-api/";
axios.defaults.withCredentials = true;

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

Vue.mixin(mapState);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
