var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","mobile-breakpoint":"800"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{attrs:{"dense":""}},[(_vm.logged_in)?_c('v-list-item',{attrs:{"link":"","to":{ name: 'configure_tfa' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("security")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Multi factor authenticatie")])],1)],1):_vm._e(),(_vm.customers && _vm.customers.length > 1)?_c('v-list-item',{attrs:{"link":"","to":"/"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Overzicht bedrijven")])],1)],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.last_selected_customer)?_c('div',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"menu-header"},[_c('img',{staticStyle:{"height":"50px"},attrs:{"src":require('@/assets/company-logos/' +
                _vm.last_selected_customer.customer.company_logo +
                '.svg')}})]),_c('v-list-item',{staticClass:"menu-header-sponsor-name"},[_c('span',[_vm._v(_vm._s(_vm.last_selected_customer.customer.name))])]),(
            _vm.last_selected_customer.customer.access_to.indexOf('export') !== -1
          )?_c('v-list-item',{attrs:{"to":{
            name: 'customer',
            params: {
              customerId: _vm.last_selected_customer_id,
            },
          }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("cloud_download")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Exports ")])],1),(_vm.total_new_exports)?_c('v-list-item-action',[_c('v-chip',{attrs:{"color":"warning","small":true}},[_vm._v(_vm._s(_vm.total_new_exports))])],1):_vm._e()],1):_vm._e(),(
            _vm.last_selected_customer.customer.access_to.indexOf(
              'returnfile'
            ) !== -1
          )?_c('v-list-item',{attrs:{"to":{
            name: 'retouren',
            params: {
              customerId: _vm.last_selected_customer_id,
            },
          }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("cloud_upload")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Retouren")])],1)],1):_vm._e(),(
            _vm.last_selected_customer.emailkit_cnt > 0 &&
            _vm.last_selected_customer.customer.access_to.indexOf('emailkit') !==
              -1
          )?_c('v-list-item',{attrs:{"to":{
            name: 'emailkits',
            params: {
              customerId: _vm.last_selected_customer_id,
            },
          }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("email")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" E-mailkits")])],1)],1):_vm._e()],1)],1):_vm._e()]),_c('v-app-bar',{attrs:{"app":"","color":_vm.isDMLeads() ? 'blue' : '#111',"dark":"","clipped-left":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),(_vm.last_selected_customer)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.appName)+": "+_vm._s(_vm.last_selected_customer.customer.name)+" ")]):_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.appName)+" ")]),_c('v-spacer'),(_vm.logged_in)?_c('v-btn',{attrs:{"color":_vm.isDMLeads() ? 'primary' : '#91BBC9'},on:{"click":_vm.logout}},[_c('v-icon',[_vm._v("exit_to_app")]),_vm._v(" Uitloggen ")],1):_vm._e()],1),_c('v-main',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }