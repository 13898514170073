import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Customer from "../views/Customer.vue";
import ExportList from "../views/ExportList.vue";
import Login from "../views/Login.vue";
import EnterTFA from "../views/EnterTFA.vue";
const ConfigureTFA = () => import("@/views/ConfigureTFA.vue");
const Retouren = () => import("@/views/Retouren.vue");
const RetourUpload = () => import("@/views/RetourUpload.vue");
const Emailkits = () => import("@/views/Emailkits.vue");
const EmailkitStats = () => import("@/views/EmailkitStats.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    components: {
      default: Home,
    },
  },
  {
    path: "/2fa",
    name: "tfa",
    components: {
      default: EnterTFA,
    },
  },
  {
    path: "/configure-2fa",
    name: "configure_tfa",
    components: {
      default: ConfigureTFA,
    },
  },
  {
    path: "/customer/:customerId/retouren/upload",
    name: "retour_upload",
    props: {
      default(route) {
        const props = { ...route.params };
        props.customerId = +props.customerId;
        return props;
      },
    },
    components: {
      default: RetourUpload,
    },
  },
  {
    path: "/customer/:customerId/retouren",
    name: "retouren",
    props: {
      default(route) {
        const props = { ...route.params };
        props.customerId = +props.customerId;
        return props;
      },
    },
    components: {
      default: Retouren,
    },
  },
  {
    path: "/customer/:customerId/campagnes",
    name: "customer",
    props: {
      default(route) {
        const props = { ...route.params };
        props.customerId = +props.customerId;
        return props;
      },
    },
    components: {
      default: Customer,
    },
  },
  {
    path: "/customer/:customerId/emailkits/:emailkitId",
    name: "emailkit-stats",
    props: {
      default(route) {
        const props = { ...route.params };
        props.customerId = +props.customerId;
        props.emailkitId = +props.emailkitId;
        return props;
      },
    },
    components: {
      default: EmailkitStats,
    },
  },
  {
    path: "/customer/:customerId/emailkits",
    name: "emailkits",
    props: {
      default(route) {
        const props = { ...route.params };
        props.customerId = +props.customerId;
        return props;
      },
    },
    components: {
      default: Emailkits,
    },
  },
  {
    name: "exports",
    path: "/customer/:customerId/campagnes/:sponsorId/exports",
    props: {
      default(route) {
        const props = { ...route.params };
        props.customerId = +props.customerId;
        props.sponsorId = +props.sponsorId;
        return props;
      },
    },
    components: {
      default: ExportList,
    },
  },
  {
    path: "/login",
    name: "login",
    components: {
      default: Login,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.name == "question") {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
        };
      }

      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
