<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app clipped mobile-breakpoint="800">
      <div v-if="last_selected_customer">
        <v-list dense>
          <v-list-item class="menu-header">
            <img
              style="height: 50px"
              :src="
                require('@/assets/company-logos/' +
                  last_selected_customer.customer.company_logo +
                  '.svg')
              "
            />
          </v-list-item>
          <v-list-item class="menu-header-sponsor-name">
            <span>{{ last_selected_customer.customer.name }}</span>
          </v-list-item>
          <v-list-item
            v-if="
              last_selected_customer.customer.access_to.indexOf('export') !== -1
            "
            :to="{
              name: 'customer',
              params: {
                customerId: last_selected_customer_id,
              },
            }"
          >
            <v-list-item-action>
              <v-icon>cloud_download</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Exports </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="total_new_exports">
              <v-chip color="warning" :small="true">{{
                total_new_exports
              }}</v-chip>
            </v-list-item-action>
          </v-list-item>

          <v-list-item
            v-if="
              last_selected_customer.customer.access_to.indexOf(
                'returnfile'
              ) !== -1
            "
            :to="{
              name: 'retouren',
              params: {
                customerId: last_selected_customer_id,
              },
            }"
          >
            <v-list-item-action>
              <v-icon>cloud_upload</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Retouren</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              last_selected_customer.emailkit_cnt > 0 &&
              last_selected_customer.customer.access_to.indexOf('emailkit') !==
                -1
            "
            :to="{
              name: 'emailkits',
              params: {
                customerId: last_selected_customer_id,
              },
            }"
          >
            <v-list-item-action>
              <v-icon>email</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> E-mailkits</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <template v-slot:append>
        <v-list dense>
          <v-list-item v-if="logged_in" link :to="{ name: 'configure_tfa' }">
            <v-list-item-action>
              <v-icon>security</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Multi factor authenticatie</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="customers && customers.length > 1" link to="/">
            <v-list-item-action>
              <v-icon>home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Overzicht bedrijven</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar app :color="isDMLeads() ? 'blue' : '#111'" dark clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title v-if="last_selected_customer">
        {{ appName }}: {{ last_selected_customer.customer.name }}
      </v-toolbar-title>
      <v-toolbar-title v-else>
        {{ appName }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="logged_in"
        :color="isDMLeads() ? 'primary' : '#91BBC9'"
        @click="logout"
      >
        <v-icon>exit_to_app</v-icon>
        Uitloggen
      </v-btn>
    </v-app-bar>

    <v-main> <router-view></router-view></v-main>
  </v-app>
</template>

<script>
import DMLeadsDetect from "@/mixins/dmleads_detect";

export default {
  name: "App",
  components: {},
  mixins: [DMLeadsDetect],
  data: () => ({
    drawer: null,
  }),
  created() {
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // auth check
        if (error.response && error.response.status) {
          if (error.response.data.authentication_required === true) {
            if (this.$route.name != "login") {
              this.$router.push({
                name: "login",
                query: {
                  redirect: this.$route.fullPath,
                },
              });
            }
          } else if (error.response.data.tfa_force_configure) {
            if (this.$route.name != "configure_tfa") {
              this.$router.push({
                name: "configure_tfa",
                query: {
                  redirect: this.$route.fullPath,
                },
              });
            }
          } else if (error.response.data.tfa_required) {
            if (this.$route.name != "tfa") {
              this.$router.push({
                name: "tfa",
                query: {
                  redirect: this.$route.fullPath,
                },
              });
            }
          }
        }

        return Promise.reject(error);
      }
    );
  },
  mounted() {
    // change title
    document.title = this.appName;
    if (!this.logged_in) {
      // try to load profile
      this.axios
        .get("profile")
        .then((res) => {
          this.$store.dispatch("setLoggedIn");
          this.$store.dispatch("setProfile", res.data.profile);

          if (this.$route.name == "login") {
            this.$router.push({
              name: "home",
            });
          }
        })
        .catch(() => {});
    }
  },
  methods: {
    logout() {
      this.axios
        .get("logout")
        .then(() => {
          this.$store.dispatch("setLoggedOut");
          this.$store.dispatch("setProfile", {});
          this.$store.dispatch("logout");
          this.$router.push({
            name: "login",
            query: {
              redirect: this.$route.fullPath,
            },
          });
        })
        .catch(() => {
          location.reload();
        });
    },
  },
};
</script>

<style lang="scss">
.v-application ul.v-breadcrumbs {
  padding-left: 0px;
  padding-bottom: 5px;
}
tbody.router-links tr td {
  cursor: pointer;
}
.menu-header {
  margin-top: 25px;
  img {
    margin: 0 auto;
    max-width: 80%;
  }
  text-align: center;
}
.menu-header-sponsor-name {
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  span {
    width: 100%;
    text-align: center;
  }
}
</style>
