var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-row',[_c('v-col',[_c('v-card',{},[_c('v-card-title',[_vm._v("Campagnes")]),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Campagne")]),_c('th',[_vm._v("Aantal exports")]),_c('th',[_vm._v("Laatste export")]),_c('th',{staticClass:"text-right"},[_vm._v("Status")])])]),(_vm.selected_customer_info && _vm.selected_customer_info.sponsors)?_c('tbody',{staticClass:"router-links"},[_vm._l((_vm.selected_customer_info.sponsors),function(sponsor,sid){return _c('router-link',{key:sid,attrs:{"to":{
                  name: 'exports',
                  params: {
                    customerId: _vm.last_selected_customer_id,
                    sponsorId: sponsor.id,
                  },
                },"tag":"tr"}},[_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v(_vm._s(sponsor.id))]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v(_vm._s(sponsor.name))]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v(" "+_vm._s(sponsor.num_exports)+" "),(sponsor.num_exports_new)?_c('v-chip',{attrs:{"color":"warning","small":true}},[_vm._v(_vm._s(sponsor.num_exports_new)+" nieuw ")]):_vm._e()],1),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}]},[_vm._v(_vm._s(sponsor.last_export))]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-right"},[(sponsor.status == 'active')?_c('v-chip',{attrs:{"color":"success","small":true,"label":true}},[_vm._v("Actief")]):_c('v-chip',{attrs:{"color":"red","small":true,"label":true}},[_vm._v("Uitgeschakeld")])],1)])}),(
                  _vm.selected_customer_info &&
                  _vm.selected_customer_info.sponsors.length == 0
                )?_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v("Geen campagnes gevonden")])]):_vm._e()],2):_vm._e()])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }