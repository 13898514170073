var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-container',{attrs:{"fluid":""}},[(_vm.logged_in)?_c('div',[_c('v-row',[_c('v-col',[_c('v-card',{},[_c('v-card-title',[_vm._v(" Welkom "+_vm._s(_vm.firstname)+" "+_vm._s(_vm.lastname)+" ")]),_c('v-list',[_c('v-subheader',[_vm._v("BEDRIJVEN")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.customers),function(item,i){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.bestPageForAcessTo(item.access_to)),expression:"bestPageForAcessTo(item.access_to)"}],key:i,attrs:{"to":{
                    name: _vm.bestPageForAcessTo(item.access_to),
                    params: {
                      customerId: item.id,
                    },
                  }}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-icon',[_c('img',{staticStyle:{"height":"30px"},attrs:{"src":require('@/assets/company-logos/' +
                          item.company_logo +
                          '.svg')}})])],1)}),1)],1)],1)],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }